
.atendimento h5{
	padding: 3% 3%;
	text-align: center;
    color: #000;
}
.atendimento p{
	padding: 3% 3%;
	text-align: justify;
}
.atendimento h6{
	text-align: center;
}
.atendimento h1 {
	color:#ffffff;
	text-shadow: 2px 2px 2px #000;
}