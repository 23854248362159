.navbar{
    background-color: #5c6c7d;
}
.fundo-art01{
    background-color: #9099a1;
    margin-top: 100px;
}
#art05{
    background-color: rgb(230, 225, 225);
    margin-top: 70px;
}
.marg-art{
    margin-top: 20px;
    margin-bottom: 50px;
}
.art05 p{
    text-align: justify;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: gray;
}
.art05 h5{
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #000;
}