.artigos{
	padding: 10px 10px;
	margin: 0px auto !important;
}
.artigos p{
    text-align: center;
	color: rgb(83, 81, 81);
}
.artigos h3, i{
    text-align: center;
	color:#000;
}
.artigos h1 {
	color:#ffffff;
	text-shadow: 2px 2px 2px #000;
}
