.rodape p{
	text-align: center;
	color: #494747;;
	padding-bottom: 2%;
}
.rodape i{
	padding-top: 15px;
}
.rodape .instagran {
    color: rgb(179, 68, 27);
}
.rodape .wattsapp {
    color: rgb(2, 150, 46);
}
.rodape .facebook {
    color: rgb(47, 62, 150);
}